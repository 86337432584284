import { DateTime } from "luxon";

interface TimestampProps {
  time?: Date | string | null;
  showTime?: boolean;
}
// New timestamp component
export const Timestamp = ({ time, showTime }: TimestampProps) => {
  const format: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    hour: showTime ? "numeric" : undefined,
    minute: showTime ? "2-digit" : undefined,
    year: "numeric",
  };
  return (
    <span>
      {time instanceof Date
        ? DateTime.fromJSDate(time).toLocaleString(format)
        : DateTime.fromISO(String(time)).toLocaleString(format)}
    </span>
  );
};

export const TimestampLog = ({ time, showTime, format }: TimestampProps & { format: "iso" | "sql" }) => {
  const formatOpts: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
    hour: showTime ? "numeric" : undefined,
    minute: showTime ? "2-digit" : undefined,
  };
  if (time instanceof Date) {
    return <span>{DateTime.fromJSDate(time).toLocaleString(formatOpts)}</span>;
  }
  if (format === "iso") {
    return <span>{DateTime.fromISO(String(time)).toLocaleString(formatOpts)}</span>;
  }
  return <span>{DateTime.fromSQL(String(time)).toLocaleString(formatOpts)}</span>;
};
